













































import { SfBanner, SfImage, SfLink, } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppStoreBanner',
  components: {
    SfBanner,
    SfLink,
    SfImage,
  },
});
